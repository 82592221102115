<template>
	<div class="container">
		<div style="text-align:left;">
			<h3>팝업샘플</h3>
		</div>
		<div>
			<input type="text" v-model="text" />
			<input type="button" value="추가" @click="list.push({})"/>
			<table>
				<thead><th>test1</th><th>test2</th></thead>
				<tbody>
					<tr v-for="(row, $index) in list" :key="$index">
						<td><input type="text" v-model="row.text1"/></td>
						<td><input type="text" v-model="row.text2"/></td>
						<td><input type="button" value="제거" @click="list.splice($index, 1)"/></td>
					</tr>
				</tbody>
			</table>
		</div>
		<div>
			<input type="button" @click="$.popup('/guide/GUD001P01', {}, 400, 300)" value="open"/>
			<input type="button" @click="$emit('close', {text : text, list : list})" value="확인"/>
			<input type="button" @click="$emit('close', false)" value="취소"/>
		</div>
	</div>
</template>

<script>
export default {
	props: ['param'],
	data() {
		return {
			text : '',
			list : []
		}
	},
	beforeCreate(){
		console.log('GUD001P01', '===== beforeCreate =====');
	},
	created(){
		console.log('GUD001P01', '===== created =====', this.param);
	},
	beforeMount(){
		console.log('GUD001P01', '===== beforeMount =====', this.param);
		if(this.param){
			this.text = this.param.text || '';
			this.list = this.param.list || [];
		}
	},
	mounted(){
		console.log('GUD001P01', '===== mounted =====', this.param);
	},
	beforeUpdate(){
		//console.log('GUD001P01', '===== beforeUpdate =====', this.param);
	},
	updated(){
		//console.log('GUD001P01', '===== updated =====', this.param);
	},
	beforeDestory(){
		console.log('GUD001P01', '===== beforeDestory =====', this.param);
	},
	destoryed(){
		console.log('GUD001P01', '===== destoryed =====', this.param);
	},
	methods: {
	},
};
</script>